import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message, Pagination } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./AdminUsers.css";

const AdminLeaderboard = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [allUser, setAllUser] = useState(null);
  const [originalUserData, setOriginalUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchEmail, setSearchEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [fromDate, setFromDate] = useState(getTodayDate());
  const [toDate, setToDate] = useState(getTodayDate());

  function handleClearFilter() {
    setSearchEmail("");
    setFromDate(getTodayDate());
    setToDate(getTodayDate());
    getAllOrders();
  }

  const getAllOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/leaderboard", {
        params: { fromDate, toDate },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data);
        setOriginalUserData(res.data.data);
        setTotalCount(res.data.data.length);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = () => {
    if (originalUserData) {
      const filteredUsers = originalUserData.filter((order) => {
        const emailMatch =
          order.customer_email &&
          order.customer_email
            .toLowerCase()
            .includes(searchEmail.toLowerCase());

        return emailMatch;
      });
      setAllUser(filteredUsers);
      setTotalCount(filteredUsers.length); // Update total count for pagination
    }
  };

  useEffect(() => {
    getAllOrders();
  }, [toDate]);

  useEffect(() => {
    handleSearch();
  }, [searchEmail, toDate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };

  const displayedUsers = allUser?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Orders</h3>
          <h6>Total Orders - {totalCount}</h6>
        </div>
        <hr />
        <div className="table-container">
          <div className="tools mb-5">
            <div className="form-fields">
              <input
                className="border"
                type="search"
                name="email"
                placeholder="Search by Email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </div>

            <div className="form-fields">
              <input
                onChange={(e) => setFromDate(e.target.value)}
                className="form-control"
                type="date"
                name="fromDate"
              />
            </div>

            <div className="form-fields">
              <input
                onChange={(e) => setToDate(e.target.value)}
                className="form-control"
                type="date"
                name="fromDate"
              />
            </div>

            <button
              className="bg-danger px-3"
              onClick={() => handleClearFilter()}
            >
              Clear Filter
            </button>
          </div>
          {loading ? (
            <div class="spinner-border text-dark" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <table className="table user-table">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Email</th>
                    <th>Total Purchase</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedUsers &&
                    displayedUsers
                      .sort((a, b) => {
                        return b.totalSpent - a.totalSpent;
                      })
                      .map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <small>{index + 1}</small>
                            </td>
                            <td>
                              <small>{user?.email}</small>
                            </td>
                            <td>
                              <small>{user?.totalSpent}</small>
                            </td>
                            <td>
                              <small>{user?.mobile}</small>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              <Pagination
                className="my-3"
                current={currentPage}
                pageSize={pageSize}
                total={totalCount}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageSizeChange}
              />
            </>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminLeaderboard;
