import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import "./AdminUsers.css";
import "./AdminAddProduct.css";

const AdminAddProduct = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [yokcash, setYokcash] = useState(null);

  const [form, setForm] = useState({
    name: "",
    desc: "",
    descTwo: "",
    api: "",
    apiName: "",
    gameName: "",
    region: "",
    pregion: "",
    tag: "",
    stock: "yes",
    images: [],
    fields: "",
    tagOne: "",
    tagTwo: "",
    tagThree: "",
    tagFour: "",
    notificationHeading: "",
    notificationDescOne: "",
    notificationDescTwo: "",
    bannerImage: null,
    instructionImage: null,
    userIdImage: null,
    playerCheckBtn: "",
    hotTags: "",
  });

  const handleCategoryChange = (selectedList) => {
    setForm((prevForm) => ({
      ...prevForm,
      category: selectedList,
    }));
  };

  const [cost, setCost] = useState([
    {
      id: "",
      amount: "",
      packData: "",
      price: "",
      resPrice: "",
      fakePrice: "",
      pimg: "",
    },
  ]);

  const handleAddCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index + 1, 0, {
      id: "",
      amount: "",
      packData: "",
      price: "",
      resPrice: "",
      fakePrice: "",
      pimg: "",
    });
    setCost(updatedCost);
  };
  const handleRemoveCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index, 1);
    setCost(updatedCost);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setForm({ ...form, [name]: e.target.files });
    } else if (
      name === "bannerImage" ||
      name === "instructionImage" ||
      name === "userIdImage"
    ) {
      setForm({ ...form, [name]: e.target.files[0] });
    } else if (
      name.startsWith("id") ||
      name.startsWith("amount") ||
      name.startsWith("packData") ||
      name.startsWith("price") ||
      name.startsWith("resPrice") ||
      name.startsWith("fakePrice") ||
      name.startsWith("pimg")
    ) {
      const index = parseInt(name.split("-")[1]);
      const updatedCost = [...cost];
      const property = name.startsWith("amount")
        ? "amount"
        : name.startsWith("packData")
        ? "packData"
        : name.startsWith("price")
        ? "price"
        : name.startsWith("resPrice")
        ? "resPrice"
        : name.startsWith("fakePrice")
        ? "fakePrice"
        : name.startsWith("pimg")
        ? "pimg"
        : "id";
      updatedCost[index][property] = value;
      setCost(updatedCost);
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append("name", form?.name);
    formData.append("api", form?.api);
    formData.append("apiName", form?.apiName);
    formData.append("gameName", form?.gameName);
    formData.append("region", form?.region);
    formData.append("pregion", form?.pregion);
    formData.append("tag", form?.tag);
    formData.append("desc", form?.desc);
    formData.append("stock", form?.stock);
    formData.append("descTwo", form?.descTwo);
    formData.append("fields", form?.fields);
    formData.append("tagOne", form?.tagOne);
    formData.append("tagTwo", form?.tagTwo);
    formData.append("tagThree", form?.tagThree);
    formData.append("tagFour", form?.tagFour);
    formData.append("playerCheckBtn", form?.playerCheckBtn);
    formData.append("hotTags", form?.hotTags);
    formData.append("notificationHeading", form?.notificationHeading);
    formData.append("notificationDescOne", form?.notificationDescOne);
    formData.append("notificationDescTwo", form?.notificationDescTwo);
    formData.append("cost", JSON.stringify(cost));
    formData.append("category", JSON.stringify(form?.category));
    formData.append("image", selectedFile);

    if (form.bannerImage) {
      formData.append("bannerImage", form.bannerImage);
    }
    if (form.instructionImage) {
      formData.append("instructionImage", form.instructionImage);
    }
    if (form.userIdImage) {
      formData.append("userIdImage", form.userIdImage);
    }

    setLoading(true);

    try {
      const res = await axios.post("/api/product/add-product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        navigate("/admin-products");
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  const getMobileLegendGame = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-mobile-legend",
        { region: form?.region },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.success) {
        setData(res.data.data.product);
      } else {
        message.error("Api Error, Try after sometime");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (form?.region !== "") {
      getMobileLegendGame();
    }
  }, [form?.region]);

  const fetchServices = async () => {
    try {
      const res = await axios.post("/api/yokcash/get-yokcash", {
        gameName: form?.gameName,
      });
      if (res.data.success) {
        setYokcash(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (form?.gameName !== "") {
      fetchServices();
    }
  }, [form?.gameName]);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Add Product</h3>
        </div>
        <hr />
        <div className="add-product-container">
          <span className="text-dark">
            <small>Product Image</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <span className="text-dark">
            <small>Banner Image</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Banner Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="bannerImage"
              required
              ref={fileInputRef}
              onChange={handleChange}
            />
          </div>

          <span className="text-dark">
            <small>Instruction Image</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Instruction Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="instructionImage"
              required
              ref={fileInputRef}
              onChange={handleChange}
            />
          </div>

          <span className="text-dark">
            <small>User Id Image</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Instruction Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="userIdImage"
              required
              ref={fileInputRef}
              onChange={handleChange}
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="name"
              onChange={handleChange}
              value={form?.name}
              type="text"
              placeholder="Enter name"
            />
          </div>

          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="desc"
              id=""
              cols="30"
              rows="3"
              placeholder="Description"
              className="form-control"
              onChange={handleChange}
              value={form?.desc}
            ></textarea>
          </div>

          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="descTwo"
              id=""
              cols="30"
              rows="3"
              placeholder="Description Two"
              className="form-control"
              onChange={handleChange}
              value={form?.descTwo}
            ></textarea>
          </div>

          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="hotTags"
              onChange={handleChange}
              value={form?.hotTags}
              type="text"
              placeholder="Enter hot tags"
            />
          </div>

          <div className="form-fields mb-3">
            <Multiselect
              id="category"
              className="multiselect-wrapper"
              isObject={false}
              onRemove={handleCategoryChange}
              onSelect={handleCategoryChange}
              selectedValues={form.category}
              options={[
                "Trending",
                "Mobile Legends",
                "PUBG",
                "Top UP",
                "Login Recharge",
                "Gift Cards",
                "OTT",
                "Flash Sale",
                "Pre Order",
                "Social Media Service",
                "Recommended",
                "Others",
              ]}
            />
          </div>
          <div className="form-fields mb-3">
            <label htmlFor="" className="form-label">
              Stock
            </label>
            <select
              onChange={handleChange}
              value={form?.stock}
              name="stock"
              className="w-100"
            >
              <option value="">Stock</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.api}
              name="api"
              className="w-100"
            >
              <option value="">API BASED?</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          {form?.api === "yes" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.apiName}
                name="apiName"
                className="w-100"
              >
                <option value="">Select API</option>
                <option value="smileOne">Smile One Api</option>
                <option value="yokcash">Yokcash Api</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "yokcash" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                <option value="Mobile Legends">Mobile Legends</option>
                <option value="Genshin Impact">Genshin Impact</option>
                <option value="Honor Of Kings">Honor Of Kings</option>
                <option value="MLBB">MLBB</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.region}
                name="region"
                className="w-100"
              >
                <option value="">Select Region</option>
                <option value="brazil">Brazil</option>
                <option value="philippines">Philippines</option>
              </select>
            </div>
          )}
          {cost &&
            cost?.map((item, index) => (
              <div className="d-flex form-fields mb-3" key={index}>
                <input
                  className="w-100"
                  name={`id-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.id || ""}
                  type="text"
                  placeholder="Enter id"
                />
                <input
                  className="w-100"
                  name={`amount-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.amount || ""}
                  type="text"
                  placeholder="Enter Amount"
                />
                <input
                  className="w-100"
                  name={`packData-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.packData || ""}
                  type="text"
                  placeholder="Enter Pack Data"
                />
                <input
                  className="w-100"
                  name={`price-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.price || ""}
                  type="text"
                  placeholder="Enter Price"
                />
                <input
                  className="w-100"
                  name={`resPrice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.resPrice || ""}
                  type="text"
                  placeholder="Enter Reseller Price"
                />
                <input
                  className="w-100"
                  name={`fakePrice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.fakePrice || ""}
                  type="text"
                  placeholder="Enter Fake Price"
                />
                <input
                  className="w-100"
                  name={`pimg-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.pimg || ""}
                  type="text"
                  placeholder="Enter Image Link"
                />
                <button onClick={() => handleAddCostField(index)}>+</button>
                {index > 0 && (
                  <button onClick={() => handleRemoveCostField(index)}>
                    -
                  </button>
                )}
              </div>
            ))}
          <span className="text-dark">
            <small>
              Product Notification (To Display =YES / To not display =NO)
            </small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              type="text"
              name="notificationHeading"
              onChange={handleChange}
              placeholder="Product Heading"
              value={form?.notificationHeading}
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              type="text"
              name="notificationDescOne"
              onChange={handleChange}
              placeholder="Product Desc One"
              value={form?.notificationDescOne}
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              type="text"
              name="notificationDescTwo"
              onChange={handleChange}
              placeholder="Product Desc Two"
              value={form?.notificationDescTwo}
            />
          </div>

          {/* FIELDS */}
          {/* FIELDS */}
          {/* FIELDS */}
          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.fields}
              name="fields"
              className="w-100"
            >
              <option value="">Select Fields</option>
              <option value="1">1 Field</option>
              <option value="2">2 Fields</option>
              <option value="3">3 Fields</option>
              <option value="4">1 Field & Selectable</option>
              <option value="5">2 Field & Selectable</option>
            </select>
          </div>
          {(form?.fields === "1" ||
            form?.fields === "2" ||
            form?.fields === "3" ||
            form?.fields === "4" ||
            form?.fields === "5") && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="tagOne"
                onChange={handleChange}
                value={form?.tagOne}
                type="text"
                placeholder="Enter Tag"
              />
            </div>
          )}
          {(form?.fields === "2" ||
            form?.fields === "3" ||
            form?.fields === "5") && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="tagTwo"
                onChange={handleChange}
                value={form?.tagTwo}
                type="text"
                placeholder="Enter tag two"
              />
            </div>
          )}
          {form?.fields === "3" && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="tagThree"
                onChange={handleChange}
                value={form?.tagThree}
                type="text"
                placeholder="Enter tag three"
              />
            </div>
          )}
          {(form?.fields === "4" || form?.fields === "5") && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="tagFour"
                onChange={handleChange}
                value={form?.tagFour}
                type="text"
                placeholder="Asia+America+Europe"
              />
            </div>
          )}

          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.playerCheckBtn}
              name="playerCheckBtn"
              className="w-100"
            >
              <option value="">MLBB PLAYERCHECK</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          {form?.playerCheckBtn === "yes" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.pregion}
                name="pregion"
                className="w-100"
              >
                <option value="">Region</option>
                <option value="br">Brazil</option>
                <option value="ph">Ph</option>
              </select>
            </div>
          )}

          <button className="w-100 py-3" onClick={handleAddProduct}>
            Add
          </button>
        </div>
      </div>

      {/* API PRO LIST  */}
      {form?.apiName === "smileOne" && data && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>SPU</th>
              <th>PRICE</th>
              <th>COST PRICE</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.id}</td>
                  <td>{item.spu}</td>
                  <td>{item.price}</td>
                  <td>{item.cost_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {form?.apiName === "yokcash" && yokcash && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {yokcash?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>{item?.nama_layanan}</td>
                  <td>{item?.kategori}</td>
                  <td>{item?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </AdminLayout>
  );
};

export default AdminAddProduct;
